import { useState, useEffect } from 'react';
import useDebounce from '../../hooks/useDebounce';
import useLegalAddress from '../../hooks/useLegalAddress';

export default function LegalInput({ field, legalAddressRecord, setLegalAddressRecord, legalInputVal, setLegalInputVal }) {
  const [loading, setLoading] = useState(false);
  const [county, setCounty] = useState(field.county)
  const [township, setTownship] = useState(field.township)

  const debouncedValue = useDebounce(legalInputVal, 500);
  useLegalAddress(debouncedValue, legalInputVal, setLoading, setLegalAddressRecord)

  function onChangeInput({ target: { id, value } }) {
    setLegalInputVal({...legalInputVal, [id]: value })
  }

  useEffect(() => {
    if (legalAddressRecord?.township_name) {
      setTownship(legalAddressRecord.township_name);
    }
    if (legalAddressRecord?.county_name) {
      setCounty(legalAddressRecord.county_name);
    }
}, [legalAddressRecord?.id])

  return (
    <>
      <div className="control-group float optional field_gps_acres">
        <label className="float optional control-label" htmlFor="field_gps_acres_js">
          Legal Description {loading ? "(Loading...)" : null}
        </label>
        <div className="controls">
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <input style={{ width: "2em", marginRight: '2px', marginLeft: '2px' }} id="legal1" name="field[legal_1]" type="text" onChange={onChangeInput} value={legalInputVal.legal1} />
            <p style={{ color: '#666', margin: '3px' }}>1/4 of the</p>
            <input style={{ width: "2em", marginRight: '2px', marginLeft: '2px' }} id="legal2" name="field[legal_2]" type="text" onChange={onChangeInput} value={legalInputVal.legal2} />
            <p style={{ color: '#666', margin: '3px' }}>1/4 of Sec</p>
            <input style={{ width: "3em", marginRight: '2px', marginLeft: '2px' }} id="section" name="field[legal_sec]" type="text" onChange={onChangeInput} value={legalInputVal.section} />
          </div>
          <div style={{ display: "flex", flexWrap: "wrap", marginTop: '5px' }}>
            <p style={{ color: '#666', margin: '3px' }}>T</p>
            <input style={{ width: "3em", marginRight: '2px', marginLeft: '2px' }} id="township" name="field[legal_township]" type="text" onChange={onChangeInput} value={legalInputVal.township} />
            <input style={{ width: "1em", marginRight: '2px', marginLeft: '2px' }} id="township_direction" name="field[legal_township_dir]" type="text" onChange={onChangeInput} value={legalInputVal.township_direction} />
            <p style={{ color: '#666', margin: '3px' }}>R</p>
            <input style={{ width: "3em", marginRight: '2px', marginLeft: '2px' }} id="range" name="field[legal_range]" type="text" onChange={onChangeInput} value={legalInputVal.range} />
            <input style={{ width: "1em", marginRight: '2px', marginLeft: '2px' }} id="range_direction" name="field[legal_range_dir]" type="text" onChange={onChangeInput} value={legalInputVal.range_direction} />
            <p style={{ color: '#666', margin: '3px' }}>State</p>
            <input style={{ width: "2em", marginRight: '2px', marginLeft: '2px' }} id="state" name="field[legal_state]" type="text" onChange={onChangeInput} value={legalInputVal.state} />
          </div>
        </div>
      </div>
      
      <div className="control-group string optional field_township">
        <label className="string optional control-label" for="field_township">Township</label>
        <div className="controls">
          <input
            className="string optional span12"
            type="text"
            value={township}
            onChange={(e) => setTownship(e.target.value)}
            name="field[township]" 
            id="field_township"
          />
        </div>
      </div>

      <div className="control-group string optional field_county">
        <label className="string optional control-label" for="field_county">County</label>
        <div className="controls">
          <input
            className="string optional span12"
            type="text"
            value={county}
            onChange={(e) => setCounty(e.target.value)}
            name="field[county]"
            id="field_county"
          />
        </div>
      </div>
    </>
  );
}