import React, { useState } from 'react';
import geojsonArea from '@mapbox/geojson-area';

export default function ActivityApplicationsTable({
  activityId,
  processingAsAppliedData: processingAsAppliedDataDefault,
  features,
  setDrawEnabled,
  setFeatures,
  field,
  asAppliedGeoJson,
  setAsAppliedGeoJson,
}) {
  const [errors, setErrors] = useState([])
  const [isPending, setIsPending] = useState(false)
  const [processingAsAppliedData, setProcessingAsAppliedData] = useState(processingAsAppliedDataDefault)

  const [asAppliedActive, setAsAppliedActive] = useState(false)

  const removeFeature = (indexToRemove) => {
    const result = [...features.slice(0, indexToRemove), ...features.slice(indexToRemove + 1)];
    setFeatures(result)
  }

  const setWholeField = () => setFeatures(field.features);

  const asAppliedAcresList = asAppliedGeoJson?.features.map(f => (geojsonArea.geometry(f.geometry) / 4046.85642).toFixed(2)) || [];
  const asAppliedSum = asAppliedAcresList.reduce((a, cv) => parseFloat(a) + parseFloat(cv), 0).toFixed(2);

  return(
    <div style={{border: '1px solid black'}}>
      <button 
        style={{
          marginLeft: '5px',
          padding: '0px',
          background: 'none',
          border: 'none',
          color: '#069',
          cursor: 'pointer',
          marginRight: '5px'
        }}
        onClick={(e) => {
          e.preventDefault()
          setDrawEnabled(false)
          setAsAppliedActive(false)
          setWholeField()
        }}
      >
        whole field
      </button>
      |
      <button 
        style={{
          marginLeft: '5px',
          marginRight: '5px',
          padding: '0px',
          background: 'none',
          border: 'none',
          color: '#069',
          cursor: 'pointer',
        }}
        onClick={(e) => {
          e.preventDefault()

          const csrfToken = document.querySelector("[name='csrf-token']").content

          const options = {
            method: 'POST',
            headers: { "X-CSRF-Token": csrfToken },
          };

          const checkStatus = async (response) => {
            if (response.status >= 200 && response.status < 300) 
              setProcessingAsAppliedData(true)
              return await response.json()
          }

          fetch(`/activities/${activityId}/as_applied_data`, options)
          .then(checkStatus)
          .catch((e) => 'setErrors(e.errors)')

        }}
      >
        as applied
      </button>
      |
      <button 
        style={{
          marginLeft: '5px',
          padding: '0px',
          background: 'none',
          border: 'none',
          color: '#069',
          cursor: 'pointer',
        }}
        onClick={(e) => {
          e.preventDefault()
          setDrawEnabled(true)
          setAsAppliedActive(false)
        }}
      >
        draw
      </button>
      {
        processingAsAppliedData
        ? (
          <div className="alert alert-success"style={{border: '2px solid green'}}>
            Processing As Applied Data
          </div>
        )
        : null
      }
      {
        features.length 
        ? (
          <>
            <h4>Activity Sections List</h4>
            <table className="table table-striped table-sm">
              <thead>
                <tr>
                  <th scope="col">Section #</th>
                  <th scope="col">Input Type</th>
                  <th scope="col">Acres</th>
                  <th scope="col">Remove</th>
                </tr>
              </thead>
              <tbody>
                {
                  features.map((f, i) => (
                    <tr key={`featurerow${i}`}>
                      <th scope="row">{i + 1}</th>
                      <th scope="row">Drawn</th>
                      <td>{(geojsonArea.geometry(f.geometry) / 4046.85642).toFixed(2)}</td>
                      <td>
                        <button 
                          style={{
                            marginLeft: '5px',
                            padding: '0px',
                            background: 'none',
                            border: 'none',
                            color: '#069',
                            cursor: 'pointer',
                          }}
                          onClick={(e) => {
                            e.preventDefault()
                            removeFeature(i)
                          }}
                        >
                          remove
                        </button>
                      </td>
                    </tr>
                  ))
                }
                {
                  asAppliedGeoJson
                    ? (
                      <tr key="as_applied_row">
                        <th scope="row">{features.length + 1}</th>
                        <th scope="row">As Applied</th>
                        <td>{asAppliedSum}</td>
                        <td>
                          <button 
                            style={{
                              marginLeft: '5px',
                              padding: '0px',
                              background: 'none',
                              border: 'none',
                              color: '#069',
                              cursor: 'pointer',
                            }}
                            onClick={(e) => {
                              const csrfToken = document.querySelector("[name='csrf-token']").content
                              const options = {
                                method: 'PATCH',
                                headers: {
                                  "X-CSRF-Token": csrfToken,
                                  "content-type": 'application/json'
                                }
                              };

                              const checkStatus = async (response) => {
                                if (response.status >= 200 && response.status < 300) 
                                  setAsAppliedGeoJson(null)
                                  return await response.json()
                              }

                              fetch(`/api/activities/${activityId}/remove_as_applied_data`, options)
                              .then(checkStatus)
                              .catch((e) => 'setErrors(e.errors)')
                            }}
                          >
                            remove
                          </button>
                        </td>
                      </tr>
                    )
                    : null
                }
              </tbody>
            </table>
          </>
        )
        : null
      }



          {
            errors.length
            ? (
              <div style={{border: '1px solid red', margin: "5px 2px", padding: '2px'}}>
                <ul>
                  {errors.map((e) => (<li key={e} style={{color: 'red'}}>{e}</li>))}
                </ul>
              </div>
            )
            : null
          }
          {
            asAppliedActive
            ? (
              <div style={{ padding: '5px', display: 'flex', justifyContent: 'flex-end' }}>
                <button 
                  onClick={(e) => {
                    e.preventDefault()
                  }}
                  type="button"
                >
                    {isPending ? 'Loading As Applied Dataing...' : 'Load As Applied Data'}
                </button>
              </div>
            )
            : null
          }
    </div>
  )
}
